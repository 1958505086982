<template>
    <div id="unoene" :class="{ noMenu: !$route.meta.showMenu }">
        <div class="tenant-selector" v-if="tenants.length > 0">
            <select v-model="currentTenant">
                <option :value="tenant.id" v-for="tenant in tenants" :key="tenant.id">{{ tenant.name }}</option>
            </select>
        </div>
        <div id="nav" v-if="$route.meta.showMenu">
            <logo color="#815cdb" background="white" />
            <i style="font-size: 0px" class="vs-icon icon-scale material-icons">check</i>
            <!--precargar-->
            <p class="nombre">{{ user.name }}</p>
            <ul>
                <li>
                    <router-link to="/empresas" v-if="canShow('/empresas')" style="color: #ffe20b">Empresas
                    </router-link>
                </li>
                <li>
                    <router-link to="/usuarios" v-if="canShow('/usuarios')" style="color: #ffe20b">Usuarios
                    </router-link>
                </li>
                <li>
                    <router-link to="/tools" v-if="canShow('/tools')" style="color: #ffe20b">Devops</router-link>
                </li>
                <li>
                    <router-link to="/clientes" v-if="canShow('/clientes')">Clientes</router-link>
                </li>
                <li>
                    <router-link to="/opiniones" v-if="canShow('/opiniones')">Opiniones</router-link>
                </li>
                <li>
                    <router-link to="/ajustes" v-if="canShow('/ajustes')">Ajustes</router-link>
                </li>
            </ul>
            <p class="footer">
                <a href="#" @click="logout">Cerrar sesión &nbsp;<i class="fas fa-sign-out-alt"></i></a>
            </p>
        </div>
        <div class="contenido" :key="reloadHack">
            <router-view />
        </div>
    </div>
</template>

<script>
import { logout } from './session'
import { mapGetters } from 'vuex'
import { getDocs, collection, db } from './firebase'
import Logo from './components/Logo.vue';

export default {
    components: { Logo },
    name: 'App',
    data() {
        return {
            tenants: [],
            currentTenant: '',
            tenantSelector: '',
            ready: false,
            reloadHack: 0
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    async mounted() {
        if (this.user.level === 5) { //mostrar selector de tenants a superadmins
            this.tenants = []
            const clients = (await getDocs(collection(db, 'clients'))).docs;
            clients.forEach(doc => this.tenants.push({ id: doc.id, ...doc.data() }))
            this.currentTenant = this.user.currentTenant
        }
    },
    methods: {
        logout() {
            logout();
        },
        canShow(path) {
            return this.$router.matcher.match(path).meta.levels.includes(this.user.level);
        }
    },
    watch: {
        currentTenant(now, old) {
            if (old) {
                this.$store.commit('setCurrentTenant', this.currentTenant)
                this.reloadHack++
            }
        },
        $route() {
            if (!this.$route.meta.showMenu) {
                document.body.className = 'fondo'
            } else {
                document.body.className = ''
            }
        }
    }
}
</script>

<style lang="scss">
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css);

body {
    background: #eee !important;
    padding: 0;
    margin: 0;
    height: 100%;
    overflow-x: hidden;
    font-size: 15px;
}

body.fondo {
    background-image: url('~@/assets/fondo.webp') !important;
    background-size: cover !important;
}

$main: #815cdb;
$highlight: #ab8cf8;

.tenant-selector {
    position: absolute;
    right: 0;
    top: 0;
}

.tenant-selector select {
    background: gray;
    color: white;
    width: 200px;
    padding: 5px;
}

.vs-input--input {
    font-size: 15px;
}

.ag-cell {
    min-height: 48px;
}

.hide {
    display: none;
}

.block {
    display: block;
}

.uploader-mini {
    .btn-upload-all {
        display: none;
    }

    .con-img-upload {
        display: flex;
        height: 32px;
        margin: 0;

        .con-input-upload {
            height: 32px;
            margin: 0;
        }

        .img-upload {
            height: 32px;
            margin: 0;
        }
    }
}

.con-text-noti * {
    font-family: Avenir;
}

.left {
    text-align: left;
}

.nice-label {
    font-weight: bold;
    font-size: 12px !important;
    color: gray !important;
}

.vs-placeholder-label {
    font-weight: bold;
    font-size: 12px !important;
    color: gray !important;
}

.vs-con-textarea {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
}

.slider-label {
    font-size: 12px;
    color: gray;
    font-weight: bold;
}

.flip-slash {
    transform: scaleX(-1) rotateY(304deg);
}

p.nombre {
    color: white;
    font-size: 14px;
    margin-top: 20px;
}

.contenido {
    width: calc(100vw - 150px);
    margin-left: 150px;
    padding: 50px;
    padding-top: 20px;
}

.noMenu .contenido {
    margin-left: 0;
    width: 100%;
}

.footer {
    position: absolute;
    bottom: 50px;
    text-align: center;
    width: 100%;
    right: 3px;
    display: block;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    color: white;
    font-size: 13px;
}

.footer a {
    font-size: 13px !important;
    font-family: Avenir, Helvetica, Arial, sans-serif !important;
    font-weight: normal !important;
    color: white !important;
    //font-size: 16px !important;
    display: inline !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
}

.inline-block {
    // display: inline-block !important;
}

#unoene {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // text-align: center;
    color: #2c3e50;
    display: flex;
    height: 100%;
    display: block !important;
}

// input {
//   border:1px solid $main !important;
// }
.vs-input__label--placeholder {
    color: $main;
    font-weight: 600;
}

#nav {
    text-align: center;

    h1 {
        margin-bottom: 50px;
        font-size: 16px;
        color: black;
        text-align: center;
        display: block;
    }

    height: 100%;
    padding: 30px 10px;
    padding-top: 10px;
    position: fixed;
    background: $main;
    width: 150px;
    min-width: 150px;
}

#nav li {
    padding: 0px 10px;
}

#nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    margin-top: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
    font-size: 14px;
    color: white;
    border-radius: 8px;
    text-decoration: none;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    display: block;
}

#nav a.router-link-exact-active {
    color: black;
    font-weight: bold;
    background: $highlight;
}

.box {
    box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.10);
    background: white;
    padding: 50px;
    margin-top: 20px;
    // font-size: 20px;
}

.mt-20 {
    margin-top: 20px;
}
</style>

