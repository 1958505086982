import Vue from 'vue/dist/vue.js'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuesax from 'vuesax'
import { startSession } from './session'
import { updateDoc, db, query, collection, getDocs, doc } from './firebase'

import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {theme:{
  colors:{
    primary:'#815cdb',
    success:'rgb(23, 201, 100)',
    danger:'rgb(242, 19, 93)',
    warning:'rgb(255, 130, 0)',
    dark:'rgb(36, 33, 69)'
  }
}});

Vue.config.productionTip = false

startSession(async user => {
  if (user.level && ![2,5].includes(user.level) && !user.currentTenant) {
    if (!user.tenants) {
      //es admin y no tiene tenant, asignarle el primero que encuentre
      //esto probablemente solo pase cuando se corra la app por primera vez (recuerda crear un tenant! ("clients"))
      const q = query(collection(db, 'clients'))
      const firstClient = (await getDocs(q)).docs[0];
      const company = {id: firstClient.id, ...firstClient.data()}
      if (company) {
        user.currentTenant = company.id;
        await updateDoc(doc(db, 'access' , user.id), user);
        store.commit('setCurrentTenant', company.id);
      }
    }
  }

  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})
