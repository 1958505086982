/*
  ┌─────────────────────────┬────────────────────────────────────────────────────┐
  │ FIREBASE SERVICE 1.0.5  │  Simplifies Firebase emulation and implementation  │
  ├─────────────────────────┴────────────────────────────────────────────────────┤
  │                                                                              │
  │ To use emulators, set the following in ".env":                               │
  │  VUE_APP_EMULATORS_IP=127.0.0.1                                                │
  │  VUE_APP_USE_EMULATORS=True                                                  │
  │                                                                              │
  ├────────────────────────────┬──────────┐                                      │
  │ Author: Sebastian Findling │  seb.cl  │                                      │
  └────────────────────────────┴──────────┴──────────────────────────────────────┘
  
 */

  import { initializeApp } from 'firebase/app';
  import {
    getAuth,
    onAuthStateChanged,
    GoogleAuthProvider,
    signInWithPopup,
    signInWithEmailAndPassword,
    signOut,
    sendPasswordResetEmail,
    FacebookAuthProvider,
    connectAuthEmulator
  } from 'firebase/auth';
  import {
    getStorage,
    connectStorageEmulator,
    ref,
    uploadBytes,
    getDownloadURL,
    deleteObject,
    uploadBytesResumable
  } from 'firebase/storage';
  import {
    getFunctions,
    connectFunctionsEmulator,
    httpsCallable
  } from 'firebase/functions';
  import {
    getFirestore,
    connectFirestoreEmulator,
    collection,
    orderBy,
    getDoc,
    getDocs,
    documentId,
    onSnapshot,
    updateDoc,
    setDoc,
    addDoc,
    doc,
    where,
    deleteDoc,
    query
  } from 'firebase/firestore';
  
  const creds = {
    apiKey: "AIzaSyBDGT3-q-fnk7mn1-L06OO7wPNPGtiM3ns",
    authDomain: "uno-ene.firebaseapp.com",
    projectId: "uno-ene",
    storageBucket: "uno-ene.appspot.com",
    messagingSenderId: "145342123801",
    appId: "1:145342123801:web:0ce7f15a85f780547e7f88",
    measurementId: "G-FBZ75C74ZG"
  };
  
  const app = initializeApp(creds);
  const db = getFirestore(app);
  const functions = getFunctions(app);
  const auth = getAuth(app);
  const storage = getStorage(app);
  
  if (
    process.env.VUE_APP_USE_EMULATORS.toLowerCase() === 'true' &&
    process.env.NODE_ENV === 'development'
  ) {
    console.log(`[Emulating Firebase]`);
    connectAuthEmulator(
      auth,
      'http://' + process.env.VUE_APP_EMULATORS_IP + ':49099'
    );
    connectFunctionsEmulator(functions, process.env.VUE_APP_EMULATORS_IP, 45001);
    connectFirestoreEmulator(db, process.env.VUE_APP_EMULATORS_IP, 48083);
    connectStorageEmulator(storage, process.env.VUE_APP_EMULATORS_IP, 49199);
  } else {
    console.log(`[NOT Emulating Firebase]`);
  }
  
  export {
    db,
    doc,
    collection,
    setDoc,
    deleteDoc,
    query,
    onSnapshot,
    signInWithPopup,
    auth,
    uploadBytes,
    documentId,
    signOut,
    getDoc,
    getDocs,
    httpsCallable,
    getFunctions,
    functions,
    storage,
    ref,
    getDownloadURL,
    uploadBytesResumable,
    deleteObject,
    signInWithEmailAndPassword,
    updateDoc,
    where,
    getAuth,
    onAuthStateChanged,
    GoogleAuthProvider,
    orderBy,
    addDoc,
    getStorage,
    sendPasswordResetEmail,
    FacebookAuthProvider
  };
  