<template>
    <section class="logo" :style="{ color: color, background: background }">ENTi</section>
</template>

<script>
import dogo from '@/assets/dogo.png'
export default {
  props: { color: { default: 'white' }, background: { default: 'black' } },
  name: 'Logo',
  data() {
    return {
      dogo: dogo
    }
  },
}
</script>

<style scoped>
section.logo {
  border-radius: 100%;
  width: 100px;
  margin: auto;
  /* margin-bottom: 50px; */
  /* background: white;
  color: red; */
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 20px;
}
</style>