import Vue from 'vue/dist/vue.js'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
      count: 0,
      user: {notLoggedIn: null},
    },
    getters: {
      user: state => state.user,
    },
    mutations: {
      setUser(state, user) {
        state.user = user
      },
      setCurrentTenant(state, tenant) {
        state.user.currentTenant = tenant
      }
    },
    actions: {
      // sendMail({commit}, mail) {
      //   return new Promise((resolve, reject) => {
      //     axios.post('/api/mail', mail).then(response => {
      //       resolve(response)
      //     }).catch(error => {
      //       reject(error)
      //     })
      //   })
      // }
    }
  })
