/**
 * Vue Firebase Session Handler (requires Vuex)
 * 
 * @author Sebastian Findling
 */

import store from './store'
import { onAuthStateChanged, GoogleAuthProvider, onSnapshot, auth,
         db, where, signInWithPopup, query, collection, doc, setDoc } from './firebase'
const provider = new GoogleAuthProvider();

export function startSession(callback) {
    onAuthStateChanged(auth, (user) => {
        if (user) {
        const account = {name: user.displayName, email: user.email, uid: user.uid, notLoggedIn: false, created:new Date(), tenants:[], currentTenant:null};
        const q = query(collection(db, "access"), where("email", "==", account.email));
        onSnapshot(q, snapshot => {
            if (!snapshot.docs[0]) return setDoc(doc(db, "access", account.uid), {...account, level: 0})
            const user = {...account, id: snapshot.docs[0].id, ...snapshot.docs[0].data()}
            store.commit('setUser', user);
            callback(user);
        })
        } else {
            store.commit('setUser', {notLoggedIn: true});
            callback({notLoggedIn: true});
        }
    });
}

export function loginWithGoogle() {
    signInWithPopup(auth, provider)
      .then((/*result*/) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;
        // self.user = result.user;
      }).catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;
        // const email = error.email;
        // const credential = GoogleAuthProvider.credentialFromError(error);
        alert('No se pudo iniciar sesión: '+errorMessage);
    });
}

export function logout() {
    auth.signOut().then(() => {
        location.replace('/');
    }).catch(error => {
        console.log(error);
    });
}