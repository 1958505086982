import Vue from 'vue/dist/vue.js'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
        { path: '/login', component: () => import('./components/Login') },
        { path: '/empresas', component: () => import('./components/Empresas'), meta: { levels: [2, 5], showMenu: true } },
        { path: '/empresas/:id', component: () => import('./components/Empresa'), meta: { levels: [2, 5], showMenu: true } },
        { path: '/usuarios', component: () => import('./components/Usuarios'), meta: { levels: [2, 5], showMenu: true } },
        { path: '/usuarios/:id', component: () => import('./components/Usuario'), meta: { levels: [2, 5], showMenu: true } },
        { path: '/opiniones', component: () => import('./components/Opiniones'), meta: { levels: [1, 5], showMenu: true } },
        { path: '/clientes', component: () => import('./components/Clientes'), meta: { levels: [1, 5], showMenu: true } },
        { path: '/ajustes', component: () => import('./components/Empresa'), meta: { levels: [1, 5], showMenu: true } },
        { path: '/tools', component: () => import('./components/Herramientas'), meta: { levels: [2, 5], showMenu: true } },
        { path: '/403', component: () => import('./components/403') },
        { path: '/unsub/:email', component: () => import('./components/Desuscribir') },
        { path: '/:uri/:customer?', component: () => import('./components/Valorador') },
        { path: '/', component: () => import('./components/Home') },
    ]
})

router.beforeEach((to, from, next) => {
    //no está logueado
    if (to.meta.levels && !store.getters.user.level) {
        console.log(`ruta requiere logearse`,);
        return next('/login');
    }

    //está en 403 pero tiene level
    if (to.path === '/403' && store.getters.user.level) {
        return next('/login');
    }

    //ruta errónea para su nivel
    if (
        (to.meta.levels && !to.meta.levels.includes(store.getters.user.level)) ||
        (to.path.match(/\/login|^\/$/) && typeof store.getters.user.level === 'number')
    ) {
        const userLevel = store.getters.user.level;
        if (userLevel === 1) return next('/clientes')
        if (userLevel === 2 || userLevel === 5) return next('/empresas')
        else return next('/403');
    }
    else if (
        to.path !== '/login' && to.matched[0].path!== '/:uri/:customer?' &&
        (!store.getters.user || store.getters.user.notLoggedIn === true)) {
        return next('/login');
    }

    next();
});


export default router